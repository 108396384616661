<template>
  <div>
    <b-card v-show="adminSession">
      <h4>Selecciona una empresa a configurar</h4>
      <form-render :fields="fields" :form.sync="form" :key="keyFormRender" ref="formRender" containerButtonsClass="col-sm-12 col-md-7 col-lg-4 col-xl-3 mt-2" class="mb-2">
        <template #buttons>
          <b-button variant="warning" v-b-tooltip.hover title="Buscar" @click="getCarriersFlash()" :disabled="!allowFind"><feather-icon icon="SearchIcon"/></b-button>
        </template>
      </form-render>
    </b-card>
    <b-card v-show="showTabs">
      <b-tabs align="left">
        <b-tab title="Couriers">
          <carriers :sections.sync="sections" :carriers.sync="carriersAssigned" @emit="sendCarriersActived" :buttonSend.sync="buttonSend" align="h"></carriers>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import carriers from './carriers-config/Carriers.vue'
import { mapGetters } from 'vuex'
import EnviaFlashService from '../enviaFlash.service'
export default {
  components: { carriers },
  data() {
    return {
      enviaFlashService: new EnviaFlashService(this),
      form: {},
      fields: [],
      keyFormRender: 0,
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      carriersAssigned: [],
      sections: [
        {id: 0, title: 'Activos'},
        {id: 1, title: 'Disponibles'}
      ],
      adminSession: false,
      showTabs: false,
      allowFind: false,
      buttonSend: true,
      loading: {
        organizations: true,
        shippersByOrganization: true,
        total: true
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      organizations: 'getOrganizationsGlobal',
      shippersByOrganization: 'getShippersByOrganizationSimplified',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    mySession () {
      this.setInitialData()
    },
    organizations () {
      this.setSelectOptions('organization_id', { options: this.organizations })
    },
    shippersByOrganization () {
      this.setSelectOptions('shipper_id', { options: this.shippersByOrganization })
    },
    generalLoading: {
      handler () {
        this.loading.shippersByOrganization = !!this.generalLoading.shippersByOrganization
        this.loading.organizations = !!this.generalLoading.getOrganizationsGlobal
      },
      deep: true
    },
    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.session_user = {
        country: this.mySession.country, 
        role: this.mySession.role,
        organization: this.mySession.organization ? this.mySession.organization : null,
        shipper: this.mySession.shipper ? this.mySession.shipper : null
      }
      if (['admin', 'superadmin'].includes(this.session_user?.role)) {
        this.adminSession = true 
        this.fields = [
          { fieldType: 'FieldSelect', name: 'organization_id', label: 'Organización', containerClass: 'container-info col-sm-12 col-md-4', change: this.changeOrganization },
          { fieldType: 'FieldSelect', name: 'shipper_id', label: 'Empresa', useLabel: true, containerClass: 'container-info col-sm-12 col-md-4', change: this.changeShipper, validation: 'required', dependency: 'organization_id' }
        ]
        if (!!this.organizations && this.organizations.length !== 0) { 
          this.setSelectOptions('organization_id', { options: this.organizations })
        }
      } else if (['marketplace'].includes(this.session_user?.role)) { 
        this.adminSession = true 
        this.fields = [
          { fieldType: 'FieldSelect', name: 'shipper_id', label: 'Empresa', useLabel: true, containerClass: 'container-info col-sm-12 col-md-4', change: this.changeShipper, validation: 'required'}
        ]
        this.organization = this.session_user.organization
        this.changeOrganization('mounted configuration', this.organization)
        if (!!this.shippersByOrganization && this.shippersByOrganization.length !== 0) { 
          this.setSelectOptions('shipper_id', { options: this.shippersByOrganization })
        }
      } else if ((['seller', 'ecommerce'].includes(this.session_user?.role))) {
        this.getCarriersFlash()
      }
    },
    getCarriersFlash() {
      this.showTabs = true
      const params = { shipper_id: this.session_user?.shipper?.id || this.form.shipper_id.id }
      this.enviaFlashService.callService('getCarriersFlash', {}, params)
        .then(resp => {
          this.carriersAssigned = { 
            ...resp.data.flash,
            carriers: resp.data.flash.carriers.map(carrier => {
              return {
                ...carrier,
                parent: carrier.enabled ? 0 : 1
              }
            })
          }
        })
    },
    sendCarriersActived(data) {
      const payload = {
        name: 'updateCarriersActivated',
        params: { shipper_id: this.session_user?.shipper?.id || this.form.shipper_id.id },
        queryParams: data,
        onSuccess: () => { this.$success(this.$t('Configuración guardada')); this.getCarriersFlash(); this.buttonSend = false },
        onError: (error) => { this.$alert(this.$t('msg-problema-guardar', {code: error})); this.buttonSend = false }
      }
      this.$store.dispatch('fetchService', payload)
    },
    changeOrganization (name, value) {
      const index = this.fields.findIndex(el => el.name === 'shipper_id')
      if (value === null) {
        this.form['shipper_id'] = null
        delete this.form['shipper_id']
        this.fields[index].options = []
        this.showTabs = false
        this.allowFind = false
        this.carriersAssigned = []
        return
      }
      const params = {
        organization_id: value.id
      }
      this.fields[index].useSkeleton = true
      this.$store.dispatch('fetchService', { name: 'getShippersByOrganizationSimplified', queryParams: {}, params, onSuccess: () => this.fields[index].useSkeleton = false })
    },
    changeShipper (name, value) {
      const index = this.fields.findIndex(el => el.name === 'shipper_id')
      if (value === null) {
        this.showTabs = false
        this.allowFind = false
        delete this.form['shipper_id']
        this.carriersAssigned = []
      } else {
        this.allowFind = true
      }
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    }
  }
}
</script>